import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicChallengeService} from "../../../_services";

const UserChallenges = () => {
    const {t, i18n} = useTranslation();
    const [userChallenges, setUserChallenges] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserChallenges = async () => {
            try {
                setLoading(true);
                const data = await publicChallengeService.getUserChallenges();
                setUserChallenges(data);
            } catch (error) {
                console.error("Error fetching user challenges:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserChallenges();
    }, []);

    const getTitle = (userChallenge) => {
        return i18n.language.startsWith('fr') ? userChallenge.title_fr : userChallenge.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="my-challanges-section p-3">
            <div className="title fw-bold-600 small mb-2">
                My Challenges
            </div>
            {!loading ? (
                <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                    <div style={{height: "100px", width: "160px"}}
                         className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "100px", width: "160px"}}
                         className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "100px", width: "160px"}}
                         className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "100px", width: "160px"}}
                         className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "100px", width: "160px"}}
                         className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                </div>
            ) : (
                <div className="my-challenges-text-holder d-flex align-items-center flex-wrap">
                    <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "160px", height: "100px"}}
                             className="border border-light-subtle shadow-sm rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                            <div className="row align-items-center mx-0 p-1" style={{height: "100px"}}>
                                <div className="col-8 p-0 h-100">
                                    <div className="info-holder d-flex flex-column h-100">
                                        <div className="challenge-title fw-bold-600 small" style={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            Challenge Title Challenge Title Challenge Title
                                        </div>
                                        <div className="mt-auto">
                                            <div className="challenge-duration text-real-small gray-text-color">
                                                30 Days
                                            </div>
                                            <div
                                                className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                <div className="me-1">Progress</div>
                                                <div className="small-circular-progress text-center">
                                                    <CircularProgressbar
                                                        value={80}
                                                        text={`${80}%`}
                                                        styles={buildStyles({
                                                            textSize: '25px',
                                                            pathColor: '#482f9f',
                                                            textColor: '#1F1345',
                                                            trailColor: '#aaa4fe',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 p-0">
                                    <div className="image-holder">
                                        <img src={defaultImage} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserChallenges;