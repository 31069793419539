import React, { useState, useEffect } from "react";
import { adminMessageService } from "../../../_services";

const ImportExcelForm = ({ sendRefreshToParent, refresh, sendImportHiddenToParent, importHidden }) => {
    const [categoriesData, setCategoriesData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminMessageService.getMessageCategories();
                setCategoriesData(data);
            } catch (error) {
                console.error("Error fetching categories data:", error);
            }
        };

        fetchData();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage("");

        const formData = new FormData();
        formData.append("category_id", selectedCategory);
        formData.append("file", file);

        adminMessageService.importMessages(formData)
            .then((res) => {
                if (res && res.status && res.status === 400) {
                    setErrorMessage(res.message);
                    return;
                }
                sendRefreshToParent(!refresh);
                sendImportHiddenToParent(!importHidden);
            })
            .catch((error) => {
                console.error("Error importing messages:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const setFormHidden = () => {
        setFile(null);
        setSelectedCategory("");
        setErrorMessage("");
        sendImportHiddenToParent(!importHidden);
    }

    return (
        <div className="main-form-holder add-message-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Import Messages from Excel</div>
            {errorMessage && (
                <div className="text-center text-danger fw-bold-600 my-3" role="alert">
                    {errorMessage}
                </div>
            )}
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="category_id">
                                    Category:
                                </label>
                                <select
                                    className="form-select form-select-sm py-2"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    name="category_id"
                                    id="category_id"
                                    required
                                >
                                    <option value="">Please Select a Category</option>
                                    {categoriesData.length > 0 && (
                                        categoriesData.map((item, index) => (
                                            <option value={item.id} key={index}>{item.name_en}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="file">
                                    Excel File:
                                </label>
                                <input
                                    type="file"
                                    className="form-control form-control-sm py-2"
                                    onChange={handleFileChange}
                                    accept=".xlsx, .xls"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Importing..." : "Import"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ImportExcelForm;