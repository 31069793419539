import Axios from "../ServiceCaller";

// Authentication
let signup = (userDetails) => {
    return Axios.post('/auth/signup', userDetails);
}
let login = (credentials) => {
    return Axios.post('/auth/login', credentials);
};
let saveToken = (token) => {
    localStorage.setItem('token_primeband', token);
};
let getToken = () => {
    return localStorage.getItem('token_primeband');
};
let logout = () => {
    localStorage.removeItem('token_primeband');
};

// Access
let isLogged = () => {
    let token = localStorage.getItem('token_primeband');
    return !!token;
};

export const accountService = {
    // Authentication
    signup,
    login,
    saveToken,
    getToken,
    logout,
    // Access
    isLogged,
};