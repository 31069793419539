import {BibleStoryEpisodesForm, BibleStoryEpisodesRow, Header} from "./Private";
import React, {useEffect, useState} from "react";
import {adminBibleStoryService} from "../../_services";
import {useParams} from "react-router-dom";

const BibleStoryEpisodes = () => {
    let {bsid} = useParams();
    const [bibleStoryEpisodesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [bibleStoryEpisodeToEdit, setBibleStoryEpisodeToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminBibleStoryService.getBibleStoryEpisodes(bsid);
                setData(data);
            } catch (error) {
                console.error("Error fetching Bible Story Episodes data:", error);
            }
        };

        fetchData();
    }, [refresh, bsid]);

    const handleEditBibleStoryEpisodes = (bibleStoryEpisode) => {
        setEditMode(true);
        setBibleStoryEpisodeToEdit(bibleStoryEpisode);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Bible Story Episodes"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bible-story-episodes-page bg-white py-3 px-1 px-sm-3 rounded-3">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                            Bible Story Episodes List
                        </div>
                        <div className="add-btn-holder">
                            <button
                                className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                                type="button" disabled={!hidden}
                                onClick={setFormHiddenAdd}>
                                Add New
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <BibleStoryEpisodesForm
                            bible_story_id={bsid}
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            bibleStoryEpisode={bibleStoryEpisodeToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div
                        className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                        <table className="table table-sm">
                            <thead>
                            <tr className="text-nowrap small">
                                <td className="text-black-50">Image</td>
                                <td className="text-black-50">Title (En)</td>
                                <td className="text-black-50">Title (Fr)</td>
                                <td className="text-black-50">Duration (En)</td>
                                <td className="text-black-50">Duration (Fr)</td>
                                <td className="text-black-50 text-end">Created</td>
                                <td className="text-black-50 text-center">...</td>
                            </tr>
                            </thead>
                            <tbody>
                            {bibleStoryEpisodesData.length > 0 ? (
                                bibleStoryEpisodesData.map((bibleStoryEpisode) => (
                                    <BibleStoryEpisodesRow
                                        key={bibleStoryEpisode.id}
                                        bibleStoryEpisode_id={bibleStoryEpisode.id}
                                        image={bibleStoryEpisode.image}
                                        title_en={bibleStoryEpisode.title_en}
                                        title_fr={bibleStoryEpisode.title_fr}
                                        duration_en={bibleStoryEpisode.duration_en}
                                        duration_fr={bibleStoryEpisode.duration_fr}
                                        created_date={bibleStoryEpisode.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditBibleStoryEpisodes={handleEditBibleStoryEpisodes}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
}

export default BibleStoryEpisodes;