import Axios from "../ServiceCaller";

let getMessageCategories = async () => {
    const { data } = await Axios.get("/public-messages/categories");
    return data.MessageCategories;
};

let getRandomVerses = async () => {
    const { data } = await Axios.get("/public-messages/random-verses");
    return data.Verses;
};

let getCategoryDataById = async (id) => {
    const { data } = await Axios.get(`public-messages/category/${id}`);
    return data.CategoryData;
};

export const publicMessageService = {
    getMessageCategories,
    getRandomVerses,
    getCategoryDataById,
};