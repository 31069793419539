import React, {useEffect, useState} from "react";
import {adminChallengeService} from "../../../_services";

const ChallengeDaysForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        day_number: "",
        task_title_en: "",
        task_title_fr: "",
        task_details_en: "",
        task_details_fr: "",
        meditation_title_en: "",
        meditation_title_fr: "",
        meditation_details_en: "",
        meditation_details_fr: "",
        assignment_title_en: "",
        assignment_title_fr: "",
        assignment_details_en: "",
        assignment_details_fr: "",
    });

    useEffect(() => {
        if (props.challengeDay) {
            setParamsValues({
                day_number: props.challengeDay.day_number,
                task_title_en: props.challengeDay.task_title_en,
                task_title_fr: props.challengeDay.task_title_fr,
                task_details_en: props.challengeDay.task_details_en,
                task_details_fr: props.challengeDay.task_details_fr,
                meditation_title_en: props.challengeDay.meditation_title_en,
                meditation_title_fr: props.challengeDay.meditation_title_fr,
                meditation_details_en: props.challengeDay.meditation_details_en,
                meditation_details_fr: props.challengeDay.meditation_details_fr,
                assignment_title_en: props.challengeDay.assignment_title_en,
                assignment_title_fr: props.challengeDay.assignment_title_fr,
                assignment_details_en: props.challengeDay.assignment_details_en,
                assignment_details_fr: props.challengeDay.assignment_details_fr,
            });
        }
    }, [props.challengeDay]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("id", props.challengeDay.challenge_day_id);
            formData.append("task_title_en", params.task_title_en);
            formData.append("task_title_fr", params.task_title_fr);
            formData.append("task_details_en", params.task_details_en);
            formData.append("task_details_fr", params.task_details_fr);
            formData.append("meditation_title_en", params.meditation_title_en);
            formData.append("meditation_title_fr", params.meditation_title_fr);
            formData.append("meditation_details_en", params.meditation_details_en);
            formData.append("meditation_details_fr", params.meditation_details_fr);
            formData.append("assignment_title_en", params.assignment_title_en);
            formData.append("assignment_title_fr", params.assignment_title_fr);
            formData.append("assignment_details_en", params.assignment_details_en);
            formData.append("assignment_details_fr", params.assignment_details_fr);

            await adminChallengeService.updateChallengeDay(formData);

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                day_number: "",
                task_title_en: "",
                task_title_fr: "",
                task_details_en: "",
                task_details_fr: "",
                meditation_title_en: "",
                meditation_title_fr: "",
                meditation_details_en: "",
                meditation_details_fr: "",
                assignment_title_en: "",
                assignment_title_fr: "",
                assignment_details_en: "",
                assignment_details_fr: "",
            });
        } catch (error) {
            console.error("Error Updating Challenge Day:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            day_number: "",
            task_title_en: "",
            task_title_fr: "",
            task_details_en: "",
            task_details_fr: "",
            meditation_title_en: "",
            meditation_title_fr: "",
            meditation_details_en: "",
            meditation_details_fr: "",
            assignment_title_en: "",
            assignment_title_fr: "",
            assignment_details_en: "",
            assignment_details_fr: "",
        });
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder update-challenge-day-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Update Challenge <b>Day {params.day_number}</b></div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row m-0">
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="task_title_en">
                                    Task Title (English):
                                </label>
                                <div className="task-title-en-holder d-flex align-items-center">
                                    <div className="task-title-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Task Title (English)"
                                            value={params.task_title_en}
                                            onChange={onChange}
                                            name="task_title_en"
                                            id="task_title_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="task_title_fr">
                                    Task Title (French):
                                </label>
                                <div className="task-title-en-holder d-flex align-items-center">
                                    <div className="task-title-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Task Title (French)"
                                            value={params.task_title_fr}
                                            onChange={onChange}
                                            name="task_title_fr"
                                            id="task_title_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="task_details_en">
                                    Task Details (English):
                                </label>
                                <div className="task-details-en-holder d-flex align-items-center">
                                    <div className="task-details-en-textarea flex-grow-1">
                                        <textarea
                                            rows={3}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Task Details (English)"
                                            value={params.task_details_en}
                                            onChange={onChange}
                                            name="task_details_en"
                                            id="task_details_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="task_details_fr">
                                    Task Details (French):
                                </label>
                                <div className="task-details-en-holder d-flex align-items-center">
                                    <div className="task-details-en-textarea flex-grow-1">
                                        <textarea
                                            rows={3}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Task Details (French)"
                                            value={params.task_details_fr}
                                            onChange={onChange}
                                            name="task_details_fr"
                                            id="task_details_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="meditation_title_en">
                                    Meditation Title (English):
                                </label>
                                <div className="meditation-title-en-holder d-flex align-items-center">
                                    <div className="meditation-title-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Meditation Title (English)"
                                            value={params.meditation_title_en}
                                            onChange={onChange}
                                            name="meditation_title_en"
                                            id="meditation_title_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="meditation_title_fr">
                                    Meditation Title (French):
                                </label>
                                <div className="meditation-title-en-holder d-flex align-items-center">
                                    <div className="meditation-title-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Meditation Title (French)"
                                            value={params.meditation_title_fr}
                                            onChange={onChange}
                                            name="meditation_title_fr"
                                            id="meditation_title_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="meditation_details_en">
                                    Meditation Details (English):
                                </label>
                                <div className="meditation-details-en-holder d-flex align-items-center">
                                    <div className="meditation-details-en-textarea flex-grow-1">
                                        <textarea
                                            rows={3}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Meditation Details (English)"
                                            value={params.meditation_details_en}
                                            onChange={onChange}
                                            name="meditation_details_en"
                                            id="meditation_details_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="meditation_details_fr">
                                    Meditation Details (French):
                                </label>
                                <div className="meditation-details-en-holder d-flex align-items-center">
                                    <div className="meditation-details-en-textarea flex-grow-1">
                                        <textarea
                                            rows={3}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Meditation Details (French)"
                                            value={params.meditation_details_fr}
                                            onChange={onChange}
                                            name="meditation_details_fr"
                                            id="meditation_details_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="assignment_title_en">
                                    Assignment Title (English):
                                </label>
                                <div className="assignment-title-en-holder d-flex align-items-center">
                                    <div className="assignment-title-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Assignment Title (English)"
                                            value={params.assignment_title_en}
                                            onChange={onChange}
                                            name="assignment_title_en"
                                            id="assignment_title_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="assignment_title_fr">
                                    Assignment Title (French):
                                </label>
                                <div className="assignment-title-en-holder d-flex align-items-center">
                                    <div className="assignment-title-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Assignment Title (French)"
                                            value={params.assignment_title_fr}
                                            onChange={onChange}
                                            name="assignment_title_fr"
                                            id="assignment_title_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="assignment_details_en">
                                    Assignment Details (English):
                                </label>
                                <div className="assignment-details-en-holder d-flex align-items-center">
                                    <div className="assignment-details-en-textarea flex-grow-1">
                                        <textarea
                                            rows={3}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Assignment Details (English)"
                                            value={params.assignment_details_en}
                                            onChange={onChange}
                                            name="assignment_details_en"
                                            id="assignment_details_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="assignment_details_fr">
                                    Assignment Details (French):
                                </label>
                                <div className="assignment-details-en-holder d-flex align-items-center">
                                    <div className="assignment-details-en-textarea flex-grow-1">
                                        <textarea
                                            rows={3}
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Assignment Details (French)"
                                            value={params.assignment_details_fr}
                                            onChange={onChange}
                                            name="assignment_details_fr"
                                            id="assignment_details_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Update"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChallengeDaysForm;