import Axios from "../ServiceCaller";

let getBibleStories = async (category) => {
    const { data } = await Axios.get(`/public-bible-stories/cat/${category}`);
    return data.BibleStories;
};

let getBibleStoryById = async (id) => {
    const { data } = await Axios.get(`/public-bible-stories/bible-story/${id}`);
    return data.BibleStory;
};

let getBibleStoryCategories = async () => {
    const { data } = await Axios.get("/public-bible-stories/categories");
    return data.BibleStoryCategories;
};

let getFeaturedBibleStories = async () => {
    const { data } = await Axios.get("/public-bible-stories/featured");
    return data.BibleStories;
};

let getRecentBibleStories = async () => {
    const { data } = await Axios.get("/public-bible-stories/recent");
    return data.BibleStories;
};

let getBibleStoryEpisodes = async (id) => {
    const { data } = await Axios.get(`/public-bible-stories/episodes/${id}`);
    return data.BibleStoryEpisodes;
};

export const publicBibleStoryService = {
    getBibleStories,
    getBibleStoryById,
    getBibleStoryCategories,
    getFeaturedBibleStories,
    getRecentBibleStories,
    getBibleStoryEpisodes,
};