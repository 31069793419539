import {BiEdit} from "react-icons/bi";

const ChallengeDaysRow = (props) => {

    const handleEditChallengeDays = () => {
        props.handleEditChallengeDays(props);
        props.sendHiddenToParent(!props.hidden);
    };

    return (
        <>
            {props.challenge_day_id > 0 ? (
                <tr className="text-nowrap small" style={{lineHeight: "50px"}}>
                    <td>Day {props.day_number}</td>
                    <td>
                        {`${props.task_title_en.substring(0, 25)}${
                            props.task_title_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.task_title_fr.substring(0, 25)}${
                            props.task_title_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.task_details_en.substring(0, 25)}${
                            props.task_details_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.task_details_fr.substring(0, 25)}${
                            props.task_details_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.meditation_title_en.substring(0, 25)}${
                            props.meditation_title_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.meditation_title_fr.substring(0, 25)}${
                            props.meditation_title_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.meditation_details_en.substring(0, 25)}${
                            props.meditation_details_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.meditation_details_fr.substring(0, 25)}${
                            props.meditation_details_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.assignment_title_en.substring(0, 25)}${
                            props.assignment_title_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.assignment_title_fr.substring(0, 25)}${
                            props.assignment_title_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.assignment_details_en.substring(0, 25)}${
                            props.assignment_details_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.assignment_details_fr.substring(0, 25)}${
                            props.assignment_details_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td className="text-center">
                        <span className="text-center">
                          <button
                              className="border-0 bg-transparent"
                              onClick={handleEditChallengeDays}
                              disabled={!props.hidden}
                          >
                            <BiEdit size="22" className="edit-icon"/>
                          </button>
                        </span>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td colSpan="5" className="text-center">
                        There is no challenge day yet
                    </td>
                </tr>
            )}
        </>
    );
};

export default ChallengeDaysRow;