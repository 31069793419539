import Axios from "../ServiceCaller";

let getMessages = async (category) => {
    const { data } = await Axios.get(`/admin-messages/cat/${category}`);
    return data.Messages;
};
let createMessage = async (messageData) => {
    const { data } = await Axios.post("/admin-messages", messageData);
    return data;
};
let importMessages = async (messageData) => {
    const { data } = await Axios.post("/admin-messages/import", messageData);
    return data;
};
let getMessageById = async (id) => {
    const { data } = await Axios.get(`/admin-messages/${id}`);
    return data.Message;
};
let updateMessage = async (messageData) => {
    const { data } = await Axios.put("/admin-messages", messageData);
    return data;
};
let deleteMessage = async (message_id) => {
    try {
        const response = await Axios.delete(`/admin-messages`, {
            data: { id: message_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Message: ", error);
        throw error;
    }
};
let getMessageCategories = async () => {
    const { data } = await Axios.get("/admin-messages/categories");
    return data.MessageCategories;
};
let createMessageCategory = async (messageCategoryData) => {
    const { data } = await Axios.post("/admin-messages/categories", messageCategoryData);
    return data;
};
let getMessageCategoryById = async (id) => {
    const { data } = await Axios.get(`/admin-messages/categories/${id}`);
    return data.MessageCategory;
};
let updateMessageCategory = async (messageCategoryData) => {
    const { data } = await Axios.put("/admin-messages/categories", messageCategoryData);
    return data;
};
let deleteMessageCategory = async (messageCategory_id) => {
    try {
        const response = await Axios.delete(`/admin-messages/categories`, {
            data: { id: messageCategory_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Message Category:", error);
        throw error;
    }
};

export const adminMessageService = {
    getMessages,
    createMessage,
    importMessages,
    getMessageById,
    updateMessage,
    deleteMessage,
    getMessageCategories,
    createMessageCategory,
    getMessageCategoryById,
    updateMessageCategory,
    deleteMessageCategory
};