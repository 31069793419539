import {BibleStoryCategories, Footer, Header} from "./Public";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {publicBibleStoryService} from "../../_services";
import defaultImage from "../../assets/images/global/default_image.jpg";
import {useTranslation} from "react-i18next";

const BibleStoriesByCategory = () => {
    let {bscid} = useParams();
    const {t, i18n} = useTranslation();
    const [bibleStoryData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBibleStories = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getBibleStories(bscid);
                setData(data);
            } catch (error) {
                console.error("Error fetching bible stories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBibleStories();
    }, [bscid]);

    const getCategoryName = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.name_fr : bibleStory.name_en;
    };
    const getTitle = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.title_fr : bibleStory.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="bible-stories-by-category-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <div className="pt-3 px-3">
                    {loading ? (
                        <div className="w-100 bible-stories-by-category-holder pt-1">
                            <div style={{height: "20px", width: "130px"}}
                                 className="rounded-2 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                            <div style={{height: "65px"}}
                                 className="rounded-3 mb-3 main-loading-effect"/>
                        </div>
                    ) : (
                        <div className="all-bible-stories-holder">
                            <div className="title-holder black-text-color fw-bold-600 mb-2 ps-1">
                                {t('bibleStories')}
                            </div>
                            {bibleStoryData.length > 0 && (
                                bibleStoryData.map((bibleStory) => (
                                    <Link to={`/bible-stories/bible-story/${bibleStory.id}`} key={bibleStory.id}
                                          className="d-flex light-shadow w-100 rounded-3 mb-3">
                                        <div className="img-holder rounded-3">
                                            {bibleStory.image ? (
                                                <img
                                                    src={`${baseURL}thedss/images/bible-stories/${bibleStory.image}`}
                                                    className="object-fit-cover border rounded-3 rounded-end-0"
                                                    width={130}
                                                    height={65}
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    src={defaultImage}
                                                    className="object-fit-cover border rounded-3 rounded-end-0"
                                                    width={130}
                                                    height={65}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <div className="w-100">
                                            <div className="p-2">
                                                <div className="name-holder small fw-bold-600 black-text-color pe-1"
                                                     style={{
                                                         overflow: 'hidden',
                                                         display: '-webkit-box',
                                                         WebkitLineClamp: 2,
                                                         WebkitBoxOrient: 'vertical',
                                                         textOverflow: 'ellipsis'
                                                     }}>
                                                    {getTitle(bibleStory)}
                                                </div>
                                            </div>
                                            <div
                                                className="main-text-dark-color text-real-small fw-bold-500 text-end pb-1 pe-3">
                                                {bibleStory.episodes_num} Episode{bibleStory.episodes_num > 1 && 's'}
                                            </div>
                                        </div>
                                    </Link>
                                )))}
                        </div>
                    )}
                </div>
                <div className="horizontal-line mx-auto"/>
                <div className="bottom-padding">
                    <BibleStoryCategories exception={bscid}/>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default BibleStoriesByCategory;