import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Error from "./_utils/Error";
import '../src/_helpers/i18n';
import PrivateRouter from "./pages/private/PrivateRouter";
import AuthRouter from "./pages/auth/AuthRouter";
import AuthGuard from "./_helpers/AuthGuard";
import IsLogged from "./_helpers/IsLogged";
import {
    Home,
    Verse,
    BibleStories,
    BibleStory,
    AllBibleStories,
    AllBibleStoryCategories,
    BibleStoriesByCategory,
    Challenge
} from "./pages/public/Public";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    {/* Public Pages */}
                    <Route index element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/verse/:cid" element={<Verse/>}/>
                    <Route path="/bible-stories" element={<BibleStories/>}/>
                    <Route path="/bible-stories/all" element={<AllBibleStories/>}/>
                    <Route path="/bible-stories/categories" element={<AllBibleStoryCategories/>}/>
                    <Route path="/bible-stories/category/:bscid" element={<BibleStoriesByCategory/>}/>
                    <Route path="/bible-stories/bible-story/:bid" element={<BibleStory/>}/>
                    <Route path="/challenges/challenge/:chid" element={<Challenge/>}/>

                    {/* Authentication Pages */}
                    <Route path="/auth/*" element={
                        <IsLogged>
                            <AuthRouter/>
                        </IsLogged>
                    }/>

                    {/* Private Pages */}
                    <Route path="/admin/*" element={
                        <AuthGuard>
                            <PrivateRouter/>
                        </AuthGuard>
                    }/>

                    {/* Catch-All 404 Page */}
                    <Route path="*" element={<Error/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;