import React, {useEffect, useState} from 'react';
import {MessagesForm, MessagesRow, ImportExcelForm} from "../../../pages/private/Private";
import {adminMessageService} from "../../../_services";
import {Link} from "react-router-dom";
import {TbCategory2} from "react-icons/tb";

const MessagesFiltered = (props) => {
    const {category, searchQuery} = props;
    const [messageData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [messageToEdit, setMessageToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);
    const [importHidden, setImportHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminMessageService.getMessages(category);
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching Message data:", error);
            }
        };

        fetchData();
    }, [refresh, category]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(messageData);
            return;
        }

        const filtered = messageData.filter((message) =>
            Object.values(message).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, messageData]);

    const handleEditMessage = (message) => {
        setEditMode(true);
        setMessageToEdit(message);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    const setImportFormHidden = () => {
        setImportHidden(!importHidden);
    }

    return (
        <div className="messages-page bg-white py-3 px-1 px-sm-3 rounded-3">
            <div className="categories-holder mb-3 text-center">
                <Link to="/admin/messages/categories"
                    className="link-as-button small rounded-1 py-2 px-3">
                    <TbCategory2 size="20"/>
                    <span className="ms-2">Manage Categories</span>
                </Link>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                    Message List
                </div>
                <div className="d-flex">
                    <div className="add-btn-holder">
                        <button
                            className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                            type="button" disabled={!importHidden}
                            onClick={setFormHiddenAdd}>
                            Add New
                        </button>
                    </div>
                    <div className="import-btn-holder ms-3">
                        <button
                            className={`main-button-color border-0 small rounded-2 py-1 px-3 ${importHidden ? "d-block" : "d-none"}`}
                            type="button" disabled={!hidden}
                            onClick={setImportFormHidden}>
                            Import File
                        </button>
                    </div>
                </div>
            </div>
            <div className={hidden ? "d-none" : "d-block"}>
                <MessagesForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    editMode={editMode}
                    message={messageToEdit}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                />
            </div>
            <div className={importHidden ? "d-none" : "d-block"}>
                <ImportExcelForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    sendImportHiddenToParent={setImportHidden}
                    importHidden={importHidden}
                />
            </div>
            <div
                className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${(!hidden || !importHidden) ? "d-none" : "d-block"}`}>
                <table className="table table-sm">
                    <thead>
                    <tr className="text-nowrap small">
                        <td className="text-black-50">Reference (En)</td>
                        <td className="text-black-50">Reference (Fr)</td>
                        <td className="text-black-50">Verse Text (En)</td>
                        <td className="text-black-50">Verse Text (Fr)</td>
                        <td className="text-black-50">Message (En)</td>
                        <td className="text-black-50">Message (Fr)</td>
                        <td className="text-black-50">Prayer (En)</td>
                        <td className="text-black-50">Prayer (Fr)</td>
                        <td className="text-black-50 text-center">Category</td>
                        <td className="text-black-50 text-end">Created</td>
                        <td className="text-black-50 text-center">...</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((message) => (
                            <MessagesRow
                                key={message.id}
                                message_id={message.id}
                                message_en={message.message_en}
                                message_fr={message.message_fr}
                                reference_en={message.reference_en}
                                reference_fr={message.reference_fr}
                                verse_text_en={message.verse_text_en}
                                verse_text_fr={message.verse_text_fr}
                                prayer_en={message.prayer_en}
                                prayer_fr={message.prayer_fr}
                                category_name_en={message.name_en}
                                category_color={message.color}
                                category_id={message.category_id}
                                created_date={message.created_date}
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                handleEditMessage={handleEditMessage}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MessagesFiltered;