import defaultImage from "../../../assets/images/global/default_image.jpg";
import {ProgressBar} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {publicChallengeService} from "../../../_services";
import ChallengeDayDetails from "./ChallengeDayDetails";

const ChallengeDetails = ({challenge_id}) => {
    const {t, i18n} = useTranslation();
    const [challengeData, setData] = useState([]);
    let [currentDay, setCurrentDay] = useState(1);
    let [lastCompletedDay, setLastCompletedDay] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicChallengeService.getChallengeById(challenge_id);
                setData(data);
            } catch (error) {
                console.error("Error fetching Challenge data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getTitle = (challenge) => {
        return i18n.language.startsWith('fr') ? challenge.title_fr : challenge.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    const calculateProgress = () => {
        if (!challengeData.days_num || challengeData.days_num === 0) return 0;
        return Math.min(100, (lastCompletedDay / challengeData.days_num) * 100);
    };
    return (
        <>
            {loading ? (
                <div className="p-3">
                    <div style={{height: "40px"}}
                         className="rounded-3 main-loading-effect mb-3"/>
                    <div style={{height: "150px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div className="horizontal-line mx-auto mb-3"/>
                    <div style={{height: "17px", width: "260px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                </div>
            ) : (
                challengeData && (
                    <div className="challenge-details-holder">
                        <div className="challenge-header-holder p-3">
                            <div className="d-flex align-items-center">
                                <div className="w-100 overflow-x-scroll text-nowrap gray-bg-color challenge-days-holder"
                                     style={{scrollbarWidth: "none"}}>
                                    {Array.from({length: challengeData.days_num}, (_, i) => (
                                        <div
                                            key={i}
                                            className={`text-center small d-inline-block text-nowrap py-2 w-25 fw-bold-500 ${currentDay === (i + 1) ? 'current' : ''}`}
                                            onClick={() => setCurrentDay(i + 1)}
                                        >
                                            {t('day')} {i + 1}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="challenge-image-holder background-image-center mt-3 rounded-2 light-shadow"
                                 style={{
                                     height: "150px",
                                     backgroundImage: `url(${challengeData.image ? `${baseURL}thedss/images/challenges/${challengeData.image}` : defaultImage})`
                                 }}>
                                <div
                                    className="content position-relative d-flex flex-column justify-content-end h-100">
                                    <div className="challenge-progress py-2 px-2 rounded-2"
                                         style={{backgroundImage: 'linear-gradient(25deg, #fff, transparent)'}}>
                                        {lastCompletedDay === 0 ? (
                                            <div className="text-center small fw-bold-600">
                                                {t('noDayCompleted')}
                                            </div>
                                        ) : lastCompletedDay >= challengeData.days_num ? (
                                            <div className="text-center small fw-bold-600">
                                                {t('allDaysCompleted')}
                                            </div>
                                        ) : (
                                            <>
                                                <div style={{
                                                    marginLeft: `${calculateProgress().toFixed() - 5}%`,
                                                    width: "fit-content"
                                                }}
                                                     className="bg-light progress-value text-real-small p-1 rounded-3 mb-1">
                                                    {calculateProgress().toFixed(0)}%
                                                </div>
                                                <ProgressBar now={calculateProgress()}/>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="horizontal-line mx-auto"/>
                        <div className="black-text-color fw-bold-600 small p-3">
                            {getTitle(challengeData)}
                        </div>
                        <ChallengeDayDetails challenge_id={challenge_id} day_number={currentDay}/>
                    </div>
                )
            )}
        </>
    );
};

export default ChallengeDetails;