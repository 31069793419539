import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicBibleStoryService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Pagination} from 'swiper/modules';
import {Link} from "react-router-dom";

const FeaturedBibleStories = () => {
    const {t, i18n} = useTranslation();
    const [bibleStories, setBibleStories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBibleStories = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getFeaturedBibleStories();
                setBibleStories(data);
            } catch (error) {
                console.error("Error fetching bible stories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBibleStories();
    }, []);

    const getTitle = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.title_fr : bibleStory.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <section className="featured-bible-stories-section p-3">
            <div className="bible-story-categories-holder">
                {loading ? (
                    <>
                        <div style={{height: "160px"}}
                             className="rounded-4 mb-1 main-loading-effect w-100"/>
                        <div style={{height: "7px", width: "100px"}}
                             className="rounded-4 mx-auto main-loading-effect"/>
                    </>
                ) : (

                    <div className="w-100">
                        <Swiper
                            pagination={{ clickable: true }}
                            modules={[Pagination]}
                            className="featured-bible-stories-slider rounded-4"
                        >
                            {bibleStories.map((bibleStory) => (
                                <SwiperSlide key={bibleStory.id}>
                                    <Link to={`/bible-stories/bible-story/${bibleStory.id}`} className="featured-box rounded-4 d-block">
                                        <div>
                                            {bibleStory.image ? (
                                                <img
                                                    src={`${baseURL}thedss/images/bible-stories/${bibleStory.image}`}
                                                    className="object-fit-cover border rounded-4 w-100"
                                                    height={160}
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    src={defaultImage}
                                                    className="object-fit-cover border rounded-4 w-100"
                                                    height={160}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <div
                                            className="info-holder mt-1">
                                            <div className="title-holder black-text-color fw-bold ps-3 text-center" style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {getTitle(bibleStory)}
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))}
                            <div className="swiper-pagination"></div>
                        </Swiper>
                    </div>
                )}
            </div>
        </section>
    )
        ;
};

export default FeaturedBibleStories;