import Axios from "../ServiceCaller";

let getChallenges = async () => {
    const {data} = await Axios.get('/admin-challenges');
    return data.Challenges;
};
let createChallenge = async (challengeData) => {
    const {data} = await Axios.post("/admin-challenges", challengeData);
    return data;
};
let getChallengeById = async (id) => {
    const {data} = await Axios.get(`/admin-challenges/${id}`);
    return data.Challenge;
};
let updateChallenge = async (challengeData) => {
    const {data} = await Axios.put("/admin-challenges", challengeData);
    return data;
};
let deleteChallenge = async (challengeId) => {
    try {
        const response = await Axios.delete(`/admin-challenges`, {
            data: {id: challengeId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Challenge: ", error);
        throw error;
    }
};

let getChallengeDays = async (challenge_id) => {
    const {data} = await Axios.get(`admin-challenges/${challenge_id}/days`);
    return data.ChallengeDays;
};
let updateChallengeDay = async (chapterData) => {
    const {data} = await Axios.put("admin-challenges/days", chapterData);
    return data;
};

export const adminChallengeService = {
    getChallenges,
    createChallenge,
    getChallengeById,
    updateChallenge,
    deleteChallenge,
    getChallengeDays,
    updateChallengeDay
};