import React, {useEffect, useState} from "react";
import {adminMessageService} from "../../../_services";

const MessagesForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [categoriesData, setCategoriesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        message_en: "",
        message_fr: "",
        reference_en: "",
        reference_fr: "",
        verse_text_en: "",
        verse_text_fr: "",
        prayer_en: "",
        prayer_fr: "",
        category_id: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminMessageService.getMessageCategories();
                setCategoriesData(data);
            } catch (error) {
                console.error("Error fetching categories data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                message_en: props.message.message_en,
                message_fr: props.message.message_fr,
                reference_en: props.message.reference_en,
                reference_fr: props.message.reference_fr,
                verse_text_en: props.message.verse_text_en,
                verse_text_fr: props.message.verse_text_fr,
                prayer_en: props.message.prayer_en,
                prayer_fr: props.message.prayer_fr,
                category_id: props.message.category_id
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                message_en: "",
                message_fr: "",
                reference_en: "",
                reference_fr: "",
                verse_text_en: "",
                verse_text_fr: "",
                prayer_en: "",
                prayer_fr: "",
                category_id: "",
            });
        }
    }, [props.editMode, props.message]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("message_en", params.message_en);
            formData.append("message_fr", params.message_fr);
            formData.append("reference_en", params.reference_en);
            formData.append("reference_fr", params.reference_fr);
            formData.append("verse_text_en", params.verse_text_en);
            formData.append("verse_text_fr", params.verse_text_fr);
            formData.append("prayer_en", params.prayer_en);
            formData.append("prayer_fr", params.prayer_fr);
            formData.append("category_id", params.category_id);

            if (isCreatingMode) {
                await adminMessageService.createMessage(formData);
            } else {
                formData.append("id", props.message.message_id);
                await adminMessageService.updateMessage(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                message_en: "",
                message_fr: "",
                reference_en: "",
                reference_fr: "",
                verse_text_en: "",
                verse_text_fr: "",
                prayer_en: "",
                prayer_fr: "",
                category_id: "",
            });
        } catch (error) {
            console.error("Error Creating/Updating message:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            message_en: "",
            message_fr: "",
            reference_en: "",
            reference_fr: "",
            verse_text_en: "",
            verse_text_fr: "",
            prayer_en: "",
            prayer_fr: "",
            category_id: "",
        });
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-message-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a message</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="pe-md-4 mb-3">
                            <div className="form-group col-md-6 pe-md-2">
                                <label className="mb-1 small fw-bold-500" htmlFor="category_id">
                                    Category:
                                </label>
                                <div className="category-id-holder d-flex align-items-center">
                                    <div className="category-id-input flex-grow-1">
                                        <select
                                            className="form-select form-select-sm py-2"
                                            value={params.category_id}
                                            onChange={onChange}
                                            name="category_id"
                                            id="category_id"
                                            required
                                        >
                                            <option value="0">Please Select a Category</option>
                                            {categoriesData.length > 0 && (
                                                categoriesData.map((item, index) => (
                                                    <option value={item.id} key={index}>{item.name_en}</option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="reference_en">
                                    Reference (English):
                                </label>
                                <div className="reference-en-holder d-flex align-items-center">
                                    <div className="reference-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Reference in English"
                                            value={params.reference_en}
                                            onChange={onChange}
                                            name="reference_en"
                                            id="reference_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="reference_fr">
                                    Reference (French):
                                </label>
                                <div className="reference-en-holder d-flex align-items-center">
                                    <div className="reference-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Reference in French"
                                            value={params.reference_fr}
                                            onChange={onChange}
                                            name="reference_fr"
                                            id="reference_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="verse_text_en">
                                    Verse Text (English):
                                </label>
                                <div className="verse-text-en-holder d-flex align-items-center">
                                    <div className="verse-text-en-input flex-grow-1">
                                 <textarea
                                     className="textarea form-control form-control-sm py-2"
                                     placeholder="Verse Text in English"
                                     value={params.verse_text_en}
                                     onChange={onChange}
                                     name="verse_text_en"
                                     id="verse_text_en"
                                     rows={3}
                                     required
                                 />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="verse_text_fr">
                                    Verse Text (French):
                                </label>
                                <div className="verse-text-en-holder d-flex align-items-center">
                                    <div className="verse-text-en-input flex-grow-1">
                                 <textarea
                                     className="textarea form-control form-control-sm py-2"
                                     placeholder="Verse Text in French"
                                     value={params.verse_text_fr}
                                     onChange={onChange}
                                     name="verse_text_fr"
                                     id="verse_text_fr"
                                     rows={3}
                                     required
                                 />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="message_en">
                                    Message (English):
                                </label>
                                <div className="message-en-holder d-flex align-items-center">
                                    <div className="message-en-input flex-grow-1">
                                 <textarea
                                     className="textarea form-control form-control-sm py-2"
                                     placeholder="Message in English"
                                     value={params.message_en}
                                     onChange={onChange}
                                     name="message_en"
                                     id="message_en"
                                     rows={3}
                                     required
                                 />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="message_fr">
                                    Message (French):
                                </label>
                                <div className="message-en-holder d-flex align-items-center">
                                    <div className="message-en-input flex-grow-1">
                                 <textarea
                                     className="textarea form-control form-control-sm py-2"
                                     placeholder="Message in French"
                                     value={params.message_fr}
                                     onChange={onChange}
                                     name="message_fr"
                                     id="message_fr"
                                     rows={3}
                                     required
                                 />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="prayer_en">
                                    Prayer (English):
                                </label>
                                <div className="prayer-en-holder d-flex align-items-center">
                                    <div className="prayer-en-input flex-grow-1">
                                 <textarea
                                     className="textarea form-control form-control-sm py-2"
                                     placeholder="Prayer in English"
                                     value={params.prayer_en}
                                     onChange={onChange}
                                     name="prayer_en"
                                     id="prayer_en"
                                     rows={3}
                                     required
                                 />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="prayer_fr">
                                    Prayer (French):
                                </label>
                                <div className="prayer-en-holder d-flex align-items-center">
                                    <div className="prayer-en-input flex-grow-1">
                                 <textarea
                                     className="textarea form-control form-control-sm py-2"
                                     placeholder="Prayer in French"
                                     value={params.prayer_fr}
                                     onChange={onChange}
                                     name="prayer_fr"
                                     id="prayer_fr"
                                     rows={3}
                                     required
                                 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MessagesForm;