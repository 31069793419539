import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicMessageService} from "../../../_services";
import {TbMessages} from "react-icons/tb";


const RandomVerses = () => {
    const {t, i18n} = useTranslation();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRandomVerses = async () => {
            try {
                setLoading(true);
                const data = await publicMessageService.getRandomVerses();
                setCategories(data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRandomVerses();
    }, []);

    const getVerseText = (verseText) => {
        return i18n.language.startsWith('fr') ? verseText.verse_text_fr : verseText.verse_text_en;
    };

    return (
        <section className="random-verses-section px-3 pt-3 pb-5 my-1">
            <div className="custom-title-holder mb-4">
                <div className="d-flex align-items-center">
                    <div className="icon-holder rounded-circle p-2 me-2">
                        <TbMessages size="30" className="title-icon" color="#000"/>
                    </div>
                    <div className="title-text">
                        <div className="fw-bold-600 small">
                            {t('randomVerseTitle')}
                        </div>
                        <div className="small text-black-50">
                            {t('optionalSubtitle')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="verses-text-holder d-flex align-items-center flex-wrap">
                {loading ? (
                    <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                        <div style={{height: "150px", width: "160px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "150px", width: "160px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "150px", width: "160px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "150px", width: "160px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "150px", width: "160px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                    </div>
                ) : (
                    <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                        {categories.map((verse) => (
                            <div key={verse.id}
                                 style={{
                                     backgroundColor: `${verse.color}`,
                                     height: "190px",
                                     width: "160px",
                                 }}
                                 className="p-2 rounded-3 small me-2 d-inline-block text-wrap overflow-hidden">
                                {`${getVerseText(verse).substring(0, 140)}${
                                    getVerseText(verse).length > 140 ? "..." : ""
                                }`}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default RandomVerses;