import {ChallengeDetails, ChallengeDescription, Footer, Header} from "./Public";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {accountService} from "../../_services";

const Challenge = () => {
    let {chid} = useParams();

    let [loginStatus, setLoginStatus] = useState(false);
    useEffect(() => {
        if (accountService.isLogged()) {
            setLoginStatus(true)
        }
    }, []);

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-challenge-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <div className="bible-story-content bottom-padding">
                    {
                        loginStatus ? (
                            <>
                                <ChallengeDetails challenge_id={chid}/>
                            </>
                        ) : (
                            <>
                                <ChallengeDescription challenge_id={chid}/>
                            </>
                        )
                    }
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Challenge;