import {NavLink} from "react-router-dom";
import {BsChatLeftQuote} from "react-icons/bs";
import {MdOutlineAutoStories} from "react-icons/md";
import {BiAbacus} from "react-icons/bi";

const BottomNavbar = () => {
    const menuItem = [
        {
            path: "/admin/messages",
            name: "Messages",
            icon: <BsChatLeftQuote size="25"/>
        },
        {
            path: "/admin/bible-stories",
            name: "Bible Stories",
            icon: <MdOutlineAutoStories size="25"/>
        },
        {
            path: "/admin/challenges",
            name: "Challenges",
            icon: <BiAbacus size="25"/>
        }
    ]

    return (
        <div
            className="the-main-bottom-navbar d-lg-none position-fixed bottom-0 w-100 py-2 px-3 d-flex align-items-center holder-of-main-page">
            <div className="bottom-navbar-links w-100 d-flex justify-content-between">
                {
                    menuItem.map((item, index) => (
                        <div className="nav-link" key={index}>
                            <NavLink to={item.path} key={index}
                                     className="nav-link-item d-flex flex-column align-items-center">
                                        <span className="icon">
                                            {item.icon}
                                        </span>
                                <span className="nav-text">{item.name}</span>
                            </NavLink>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default BottomNavbar;