import {GoPlusCircle} from "react-icons/go";
import {NavLink} from "react-router-dom";
import {TbMessages} from "react-icons/tb";
import {MdMood} from "react-icons/md";
import {LuStore} from "react-icons/lu";

const Footer = () => {
    const menuItem = [
        {
            path: "/",
            name: "Mood",
            icon: <MdMood size="35"/>
        },
        {
            path: "/bible-stories",
            name: "Bible Stories",
            icon: <TbMessages size="35"/>
        },
        {
            path: "https://shop.holylane.io/",
            name: "Shop",
            icon: <LuStore size="35"/>,
            external: true
        },
        {
            path: "/more",
            name: "More",
            icon: <GoPlusCircle size="35"/>
        },
    ]

    return (
        <footer className="main-footer position-fixed bottom-0 d-flex align-items-center">
            <div className="bottom-navbar-links w-100 d-flex justify-content-between">
                {
                    menuItem.map((item, index) => (
                        <div className="nav-link" key={index}>
                            {item.external ? (
                                <a href={item.path} target="_blank" rel="noopener noreferrer" className="nav-link-item d-flex flex-column align-items-center py-2">
                                    <span className="icon">
                                        {item.icon}
                                    </span>
                                    <span className="nav-text">{item.name}</span>
                                </a>
                            ) : (
                                <NavLink to={item.path} className="nav-link-item d-flex flex-column align-items-center py-2">
                                    <span className="icon">
                                        {item.icon}
                                    </span>
                                    <span className="nav-text">{item.name}</span>
                                </NavLink>
                            )}
                        </div>
                    ))
                }
            </div>
        </footer>
    );
};

export default Footer;