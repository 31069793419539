import React, {useEffect, useRef, useState} from 'react';
import {publicBibleStoryService} from "../../../_services";
import defaultBackground from "../../../assets/images/global/default_image.jpg";
import {FaPlay} from "react-icons/fa6";
import {useTranslation} from "react-i18next";

const PublicBibleStoryEpisodes = ({bibleStoryId}) => {
    const {t, i18n} = useTranslation();
    const [bibleStoryEpisodesData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [playing, setPlaying] = useState(null);

    const audioRefs = useRef(new Map());

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getBibleStoryEpisodes(bibleStoryId);
                setData(data);
            } catch (error) {
                console.error("Error fetching Bible Story Episodes data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [bibleStoryId]);

    const getTitle = (episode) => {
        return i18n.language.startsWith('fr') ? episode.title_fr : episode.title_en;
    };

    const getDuration = (episode) => {
        return i18n.language.startsWith('fr') ? episode.duration_fr : episode.duration_en;
    };

    const getFileName = (episode) => {
        return i18n.language.startsWith('fr') ? episode.file_name_fr : episode.file_name_en;
    };

    const handleAudioEnd = () => {
        setPlaying(null);
    };

    const togglePlay = (id) => {
        const currentAudio = audioRefs.current.get(id);
        if (!currentAudio) return;

        if (playing === id) {
            if (currentAudio.paused) {
                currentAudio.play();
                setPlaying(id);
            } else {
                currentAudio.pause();
                setPlaying(null);
            }
        } else {
            if (playing !== null) {
                const playingAudio = audioRefs.current.get(playing);
                if (playingAudio) {
                    playingAudio.pause();
                    playingAudio.currentTime = 0;
                }
            }
            currentAudio.play();
            setPlaying(id);
        }
    };

    useEffect(() => {
        if (playing !== null) {
            const playingAudio = audioRefs.current.get(playing);
            if (playingAudio) {
                playingAudio.pause();
                playingAudio.currentTime = 0;
            }
            setPlaying(null);
        }
    }, [i18n.language]);

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="episodes-section gray-bg-color rounded-top-4 p-3 pt-4">
            {loading ? (
                <div className="fw-bold black-text-color mb-3">
                    <div style={{height: "20px", width: "100px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div style={{height: "80px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div style={{height: "80px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div style={{height: "80px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div style={{height: "80px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div style={{height: "80px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div style={{height: "80px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                </div>
            ) : (
                <>
                    <div className="fw-bold black-text-color mb-3">
                        {t('episodes')}
                    </div>
                    <div className="episodes-holder">
                        {bibleStoryEpisodesData.length > 0 && (
                            bibleStoryEpisodesData.map((episode) => (
                                <div key={episode.id} className="light-shadow bg-white rounded-2 episode-item mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="image-holder me-3">
                                            <img
                                                src={episode.image ? `${baseURL}thedss/images/bible-story-episodes/${episode.image}` : defaultBackground}
                                                alt="episode" width="80" height="80"/>
                                        </div>
                                        <div className="episode-info mb-auto pt-2">
                                            <div className="black-text-color fw-bold-600 mb-1">
                                                {getTitle(episode)}
                                            </div>
                                            <div className="gray-text-color small">
                                                {getDuration(episode)}
                                            </div>
                                        </div>
                                        <div className="episode-play-status ms-auto pe-3">
                                            <audio ref={(el) => audioRefs.current.set(episode.id, el)}
                                                   src={`${baseURL}thedss/sounds/bible-story-episodes/${getFileName(episode)}`}
                                                   onEnded={() => handleAudioEnd()}/>
                                            {playing === episode.id && !audioRefs.current.get(episode.id)?.paused ?
                                                <div className="music-bars" onClick={() => togglePlay(episode.id)}>
                                                    <span className="bar"/>
                                                    <span className="bar"/>
                                                    <span className="bar"/>
                                                    <span className="bar"/>
                                                    <span className="bar"/>
                                                </div>
                                                : (
                                                    <div onClick={() => togglePlay(episode.id)}
                                                         className="play-btn-holder main-background-color p-2 rounded-circle d-flex align-items-center justify-content-center">
                                                        <FaPlay size="20" className="text-white ps-1"/>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default PublicBibleStoryEpisodes;