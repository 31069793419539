import UserChallenges from "./UserChallenges";
import AllChallenges from "./AllChallenges";
import {useEffect, useState} from "react";
import {accountService} from "../../../_services";

const ChallengesSection = () => {
    let [loginStatus, setLoginStatus] = useState(false);
    useEffect(() => {
        if (accountService.isLogged()) {
            setLoginStatus(true)
        }
    }, []);
    return (
        <div className="better-me-challenge-section-holder pb-5">
            {
                loginStatus && (
                    <>
                        <UserChallenges/>
                        <div className="horizontal-line mx-auto"/>
                    </>
                )
            }
            <div className="horizontal-line mx-auto"/>
            <AllChallenges/>
        </div>
    );
};

export default ChallengesSection;