import {useEffect, useState} from "react";
import {Header, BibleStoriesFiltered} from "./Private";
import {adminBibleStoryService} from "../../_services";

const BibleStories = () => {
    const [bibleStoryCategoriesData, setData] = useState([]);
    const [category, setCategory] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminBibleStoryService.getBibleStoryCategories();
                setData(data);
            } catch (error) {
                console.error("Error fetching Bible Story Categories data:", error);
            }
        };

        fetchData();
    }, []);

    const changeCategory = (category) => {
        setCategory(category);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    return (
        <>
            <Header title="Bible Stories"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                    <div className="col-6 col-xl-4 col-xxl-3">
                        <div className="form-group">
                            <label className="mb-1 text-real-small fw-bold-500" htmlFor="search">
                                Search
                            </label>
                            <input
                                type="text"
                                className="form-control form-select-sm text-real-small"
                                value={searchQuery}
                                id="search"
                                onChange={handleSearchInputChange}
                                placeholder="title, summary, categories..."
                            />
                        </div>
                    </div>
                    <div className="col-6 col-xl-4 col-xxl-3">
                        <div className="form-group">
                            <label className="mb-1 text-real-small fw-bold-500" htmlFor="categories">
                                Category
                            </label>
                            <select
                                className="form-select form-select-sm text-real-small"
                                onChange={(e) => changeCategory(e.target.value)}
                                id="categories"
                            >
                                <option value="0">All</option>
                                {
                                    bibleStoryCategoriesData.map((item) => (
                                        <option value={item.id} key={item.id}>{item.name_en}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <BibleStoriesFiltered
                    category={category}
                    searchQuery={searchQuery}
                />
                {/*End Page Content*/}
            </div>
        </>
    );
}

export default BibleStories;