import defaultImage from "../../../assets/images/global/default_image.jpg";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {publicChallengeService} from "../../../_services";

const ChallengeDescription = ({challenge_id}) => {
    const {t, i18n} = useTranslation();
    const [challengeData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicChallengeService.getChallengeById(challenge_id);
                setData(data);
            } catch (error) {
                console.error("Error fetching Challenge data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getTitle = (challenge) => {
        return i18n.language.startsWith('fr') ? challenge.title_fr : challenge.title_en;
    };

    const getDescription = (challenge) => {
        return i18n.language.startsWith('fr') ? challenge.description_fr : challenge.description_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <>
            {loading ? (
                <div className="p-3">
                    <div style={{height: "150px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div className="horizontal-line mx-auto mb-3"/>
                    <div style={{height: "17px", width: "235px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div style={{height: "18px", width: "150px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div style={{height: "16px", width: "100%"}}
                         className="rounded-2 main-loading-effect mb-1"/>
                    <div style={{height: "16px", width: "100%"}}
                         className="rounded-2 main-loading-effect mb-1"/>
                    <div style={{height: "16px", width: "100%"}}
                         className="rounded-2 main-loading-effect mb-1"/>
                    <div style={{height: "16px", width: "100%"}}
                         className="rounded-2 main-loading-effect mb-1"/>
                    <div style={{height: "16px", width: "100%"}}
                         className="rounded-2 main-loading-effect mb-1"/>
                    <div style={{height: "16px", width: "100%"}}
                         className="rounded-2 main-loading-effect mb-1"/>
                    <div style={{height: "16px", width: "100%"}}
                         className="rounded-2 main-loading-effect mb-1"/>
                </div>
            ) : (
                challengeData && (
                    <div className="challenge-details-holder">
                        <div className="challenge-image-holder p-3">
                            <div className="background-image-center rounded-2 light-shadow"
                                 style={{
                                     height: "150px",
                                     backgroundImage: `url(${challengeData.image ? `${baseURL}thedss/images/challenges/${challengeData.image}` : defaultImage})`
                                 }}>
                                <div
                                    className="content position-relative d-flex flex-column justify-content-between h-100">
                                    <div className="challenge-title black-text-color py-2 ps-2 fw-bold-500"
                                         style={{backgroundImage: 'linear-gradient(25deg, #ffffff, transparent)'}}>
                                        {getTitle(challengeData)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="horizontal-line mx-auto"/>
                        <div className="challenge-info-holder p-3">
                            <div className="duration-holder black-text-color fw-bold-600 small mb-3">
                                {t('challengeDuration')}: <span className="fw-normal">
                                {`${challengeData.days_num} ${t('day')}${challengeData.days_num > 1 ? 's' : ''}`}</span>
                            </div>
                            <div className="fw-bold-600 small black-text-color mb-2">
                                {t('challengeDescription')}
                            </div>
                            <div className="gray-text-color small">
                                {getDescription(challengeData)}
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default ChallengeDescription;