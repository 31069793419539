import Axios from "../ServiceCaller";

let getChallenges = async () => {
    const { data } = await Axios.get('/public-challenges');
    return data.Challenges;
};

let getChallengeById = async (id) => {
    const { data } = await Axios.get(`/public-challenges/${id}`);
    return data.Challenge;
};
let getChallengeDayDetails = async (challenge_id, day_number) => {
    const { data } = await Axios.get(`/public-challenges/day/${challenge_id}/${day_number}`);
    return data.ChallengeDay;
};

export const publicChallengeService = {
    getChallenges,
    getChallengeById,
    getChallengeDayDetails,
};