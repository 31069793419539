import React, {useEffect, useState} from 'react';
import {ChallengeDaysForm, ChallengeDaysRow, Header} from "./Private";
import {adminChallengeService} from "../../_services";
import {useParams} from "react-router-dom";

const ChallengeDays = (props) => {
    let {chid} = useParams();
    const [challengeDayData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [challengeDayToEdit, setChallengeDayToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminChallengeService.getChallengeDays(chid);
                setData(data);
            } catch (error) {
                console.error("Error fetching Challenge Days data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditChallengeDays = (challengeDay) => {
        setChallengeDayToEdit(challengeDay);
    };

    return (
        <>
            <Header title="Challenge Days"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="challenge-days-page bg-white py-3 px-1 px-sm-3 rounded-3">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                            Challenge Days List
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <ChallengeDaysForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            challengeDay={challengeDayToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div
                        className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                        <table className="table table-sm">
                            <thead>
                            <tr className="text-nowrap small">
                                <td className="text-black-50">Days</td>
                                <td className="text-black-50">Task Title (En)</td>
                                <td className="text-black-50">Task Title (Fr)</td>
                                <td className="text-black-50">Task Details (En)</td>
                                <td className="text-black-50">Task Details (Fr)</td>
                                <td className="text-black-50">Meditation Title (En)</td>
                                <td className="text-black-50">Meditation Title (Fr)</td>
                                <td className="text-black-50">Meditation Details (En)</td>
                                <td className="text-black-50">Meditation Details (Fr)</td>
                                <td className="text-black-50">Assignment Title (En)</td>
                                <td className="text-black-50">Assignment Title (Fr)</td>
                                <td className="text-black-50">Assignment Details (En)</td>
                                <td className="text-black-50">Assignment Details (Fr)</td>
                                <td className="text-black-50 text-center">...</td>
                            </tr>
                            </thead>
                            <tbody>
                            {challengeDayData.length > 0 ? (
                                challengeDayData.map((challengeDay) => (
                                    <ChallengeDaysRow
                                        key={challengeDay.id}
                                        challenge_day_id={challengeDay.id}
                                        day_number={challengeDay.day_number}
                                        task_title_en={challengeDay.task_title_en}
                                        task_title_fr={challengeDay.task_title_fr}
                                        task_details_en={challengeDay.task_details_en}
                                        task_details_fr={challengeDay.task_details_fr}
                                        meditation_title_en={challengeDay.meditation_title_en}
                                        meditation_title_fr={challengeDay.meditation_title_fr}
                                        meditation_details_en={challengeDay.meditation_details_en}
                                        meditation_details_fr={challengeDay.meditation_details_fr}
                                        assignment_title_en={challengeDay.assignment_title_en}
                                        assignment_title_fr={challengeDay.assignment_title_fr}
                                        assignment_details_en={challengeDay.assignment_details_en}
                                        assignment_details_fr={challengeDay.assignment_details_fr}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditChallengeDays={handleEditChallengeDays}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
            </div>
        </>

    )
        ;
};

export default ChallengeDays;