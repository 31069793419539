import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            verseForYou: "Verse for you",
            selectMoodAndLet: "Select your mood and let God speak to you",
            betterMeChallenge: "Better me challenge",
            moodQuestion: "How are you feeling now?",
            randomVerseTitle: "Some random verses",
            wordsToCategory: "God’s word for the",
            stayCloser: "Stay Closer to God",
            theMessage: "The Message",
            letsPray: "Let us pray 🙏",
            categories: "Categories",
            seeAll: "See All",
            bibleStories: "Bible Stories",
            allChallenges: "All challenges",
            day: "Day",
            duration: "Duration",
            // All Bible Stories Page:
            search: "Search",
            searchInBibleStories: "search in bible stories...",
            all: "All",
            // All Bible Story Categories
            bibleStoryCategories: "Bible story categories",
            otherCategories: "Other categories",
            // Episodes Page
            episodes: 'Episodes',
            // Challenge Page
            challengeDescription: 'Challenge description',
            challengeDuration: 'Duration of the challenge',
            noDayCompleted: 'No day is completed yet',
            allDaysCompleted: "Congratulations you've already finished this challenge",
        }
    },
    fr: {
        translation: {
            verseForYou: "Verset pour vous",
            selectMoodAndLet: "Sélectionnez votre humeur et laissez Dieu vous parler",
            betterMeChallenge: "Mieux me défier",
            moodQuestion: "Comment te sens-tu maintenant ?",
            randomVerseTitle: "Quelques versets aléatoires",
            wordsToCategory: "La parole de Dieu pour le",
            stayCloser: "Restez proche de Dieu",
            theMessage: "Le message",
            letsPray: "Prions ensemble 🙏",
            categories: "Catégories",
            seeAll: "Voir Tout",
            bibleStories: "Récits bibliques",
            allChallenges: "Tous les défis",
            day: "Jour",
            duration: "Durée",
            // All Bible Stories Page:
            search: "Rechercher",
            searchInBibleStories: "rechercher dans les récits bibliques...",
            all: "Tous",
            // All Bible Story Categories
            bibleStoryCategories: "Catégories de récits bibliques",
            otherCategories: "Autres catégories",
            // Episodes Page
            episodes: 'Épisodes',
            // Challenge Page
            challengeDescription: 'Description du défi',
            challengeDuration: 'La durée du défi',
            noDayCompleted: 'Pas encore de jour terminé',
            allDaysCompleted: "Félicitations, vous avez déjà terminé ce défi",
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
