import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicBibleStoryService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {Link} from "react-router-dom";

const RecentBibleStories = () => {
    const {t, i18n} = useTranslation();
    const [bibleStories, setBibleStories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBibleStories = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getRecentBibleStories();
                setBibleStories(data);
            } catch (error) {
                console.error("Error fetching bible stories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBibleStories();
    }, []);

    const getCategoryName = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.name_fr : bibleStory.name_en;
    };
    const getTitle = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.title_fr : bibleStory.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <section className="recent-bible-stories-section p-3 pb-5">
            <div className="d-flex align-items-center justify-content-between mb-3 px-1">
                <div className="title fw-bold-600">
                    {t('bibleStories')}
                </div>
                <Link to="/bible-stories/all" className="link-text-holder fw-bold-600 main-text-color">
                    {t('seeAll')}
                </Link>
            </div>
            <div className="bible-story-categories-holder">
                {loading ? (
                    <div className="w-100">
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                    </div>
                ) : (
                    <div className="w-100">
                        {bibleStories.map((bibleStory) => (
                            <Link to={`/bible-stories/bible-story/${bibleStory.id}`} key={bibleStory.id} className="d-flex light-shadow w-100 rounded-3 mb-3">
                                <div className="img-holder rounded-3">
                                    {bibleStory.image ? (
                                        <img
                                            src={`${baseURL}thedss/images/bible-stories/${bibleStory.image}`}
                                            className="object-fit-cover border rounded-3 rounded-end-0"
                                            width={130}
                                            height={65}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={defaultImage}
                                            className="object-fit-cover border rounded-3 rounded-end-0"
                                            width={130}
                                            height={65}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <div className="w-100">
                                    <div className="p-2">
                                        <div className="name-holder small fw-bold-600 black-text-color pe-1"
                                             style={{
                                                 overflow: 'hidden',
                                                 display: '-webkit-box',
                                                 WebkitLineClamp: 2,
                                                 WebkitBoxOrient: 'vertical',
                                                 textOverflow: 'ellipsis'
                                             }}>
                                            {getTitle(bibleStory)}
                                        </div>
                                    </div>
                                    <div className="main-text-dark-color text-real-small fw-bold-500 text-end pb-1 pe-3">
                                        {bibleStory.episodes_num} Episode{bibleStory.episodes_num > 1 && 's'}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default RecentBibleStories;