import {Link, NavLink, useNavigate} from "react-router-dom";
import mainLogo from "../../../assets/images/global/primeband_logo.png"
import {
    GoSignOut,
} from "react-icons/go";
import {accountService} from "../../../_services";
import {BsChatLeftQuote} from "react-icons/bs";
import {MdOutlineAutoStories} from "react-icons/md";
import {BiAbacus} from "react-icons/bi";

const Sidebar = () => {
    let navigate = useNavigate();
    const menuItem = [
        {
            path: "/admin/messages",
            name: "Messages",
            icon: <BsChatLeftQuote size="25"/>
        },
        {
            path: "/admin/bible-stories",
            name: "Bible Stories",
            icon: <MdOutlineAutoStories size="25"/>
        },
        {
            path: "/admin/challenges",
            name: "Challenges",
            icon: <BiAbacus size="25"/>
        }
    ]

    const logout = () => {
        accountService.logout();
        navigate("/auth/login")
    }

    return (
        <div className="the-main-sidebar position-fixed top-0 start-0 d-none d-lg-block">
            <div className="h-100 d-flex flex-column px-3 pb-3">
                <div className="sidebar-logo-holder mb-2">
                    <Link to="/admin/messages"
                          className="logo-holder d-flex align-items-center justify-content-center h-100">
                        <img src={mainLogo} alt="Primeband" className="logo-img"/>
                    </Link>
                </div>
                <div className="sidebar-links">
                    {
                        menuItem.map((item, index) => (
                            <div className="nav-link mt-2 list-unstyled align-items-center" key={index}>
                                <div className="menu-item">
                                    <NavLink to={item.path} key={index}
                                             className="nav-link-item rounded-3 py-3 px-2 d-flex align-items-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            {item.icon}
                                        </div>
                                        <div className="nav-text">{item.name}</div>
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="mt-auto">
                    <div className="nav-link list-unstyled align-items-center">
                        <div className="menu-item logout-button">
                            <button type="button" onClick={logout}
                                    className="nav-link-item rounded-3 py-3 px-2 d-flex align-items-center border-0 bg-transparent">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <GoSignOut size="25"/>
                                </div>
                                <div className="nav-text">Logout</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Sidebar;