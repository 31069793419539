import {BibleStoryCategoriesForm, BibleStoryCategoriesRow, Header} from "./Private";
import React, {useEffect, useState} from "react";
import {adminBibleStoryService} from "../../_services";

const BibleStoryCategories = () => {

    const [bibleStoryCategoriesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [bibleStoryCategoriesToEdit, setBibleStoryCategoriesToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminBibleStoryService.getBibleStoryCategories();
                setData(data);
            } catch (error) {
                console.error("Error fetching Bible Story Categories data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditBibleStoryCategories = (bibleStoryCategories) => {
        setEditMode(true);
        setBibleStoryCategoriesToEdit(bibleStoryCategories);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Bible Story Categories"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bible-story-categories-page bg-white py-3 px-1 px-sm-3 rounded-3">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                            Bible Story Categories List
                        </div>
                        <div className="add-btn-holder">
                            <button
                                className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                                type="button" disabled={!hidden}
                                onClick={setFormHiddenAdd}>
                                Add New
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <BibleStoryCategoriesForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            bibleStoryCategories={bibleStoryCategoriesToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div
                        className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                        <table className="table table-sm">
                            <thead>
                            <tr className="text-nowrap small">
                                <td className="text-black-50">Image</td>
                                <td className="text-black-50">Name (En)</td>
                                <td className="text-black-50">Name (Fr)</td>
                                <td className="text-black-50 text-end">Created</td>
                                <td className="text-black-50 text-center">...</td>
                            </tr>
                            </thead>
                            <tbody>
                            {bibleStoryCategoriesData.length > 0 ? (
                                bibleStoryCategoriesData.map((bibleStoryCategories) => (
                                    <BibleStoryCategoriesRow
                                        key={bibleStoryCategories.id}
                                        bibleStoryCategory_id={bibleStoryCategories.id}
                                        image={bibleStoryCategories.image}
                                        name_en={bibleStoryCategories.name_en}
                                        name_fr={bibleStoryCategories.name_fr}
                                        created_date={bibleStoryCategories.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditBibleStoryCategories={handleEditBibleStoryCategories}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
}

export default BibleStoryCategories;