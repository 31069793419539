import {BiEdit, BiTrash} from "react-icons/bi";
import {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {adminMessageService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

const MessagesRow = (props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDeleteMessage = async () => {
        setIsDeleting(true);

        try {
            await adminMessageService.deleteMessage(props.message_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting message:", error);
        } finally {
            setIsDeleting(false);
            setShowConfirmModal(false);
        }
    };

    const handleEditMessage = () => {
        props.handleEditMessage(props);
        props.sendHiddenToParent(!props.hidden);
    };

    return (
        <>
            {props.message_id > 0 ? (
                <tr className="text-nowrap small">
                    <td>
                        {`${props.reference_en.substring(0, 25)}${
                            props.reference_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.reference_fr.substring(0, 25)}${
                            props.reference_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.verse_text_en.substring(0, 25)}${
                            props.verse_text_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.verse_text_fr.substring(0, 25)}${
                            props.verse_text_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.message_en.substring(0, 25)}${
                            props.message_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.message_fr.substring(0, 25)}${
                            props.message_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.prayer_en.substring(0, 25)}${
                            props.prayer_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.prayer_fr.substring(0, 25)}${
                            props.prayer_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td className="text-center text-real-small" style={{backgroundColor: `${props.category_color}`}}>
                        {props.category_name_en}
                    </td>
                    <td className="text-end">
                        <span className="text-black-50">
                          {props.created_date ? getDate(props.created_date) : ""}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className="text-center">
                          <button
                              className="border-0 bg-transparent"
                              onClick={handleEditMessage}
                              disabled={!props.hidden}
                          >
                            <BiEdit size="22" className="edit-icon"/>
                          </button>
                          <button
                              className="border-0 bg-transparent"
                              onClick={() => setShowConfirmModal(true)}
                          >
                            <BiTrash size="22" className="remove-icon mx-auto"/>
                          </button>
                        </span>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td colSpan="11" className="text-center">
                        There is no message yet
                    </td>
                </tr>
            )}

            {/* Confirmation Modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this message?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModal(false)}
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteMessage}
                        disabled={isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MessagesRow;
