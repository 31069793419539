import {useEffect, useState} from "react";
import {publicBibleStoryService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const PublicBibleStoriesFiltered = (props) => {
    const {t, i18n} = useTranslation();
    const {category, searchQuery} = props;
    const [bibleStoryData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBibleStories = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getBibleStories(category);
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching bible stories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBibleStories();
    }, [category]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(bibleStoryData);
            return;
        }

        const filtered = bibleStoryData.filter((bibleStory) => {
            const languagePrefix = i18n.language.startsWith('fr') ? 'fr' : 'en';
            const titleKey = `title_${languagePrefix}`;
            const summaryKey = `summary_${languagePrefix}`;
            const categoryKey = `name_${languagePrefix}`;

            return bibleStory[titleKey].toLowerCase().includes(searchQuery.toLowerCase()) ||
                bibleStory[summaryKey].toLowerCase().includes(searchQuery.toLowerCase()) ||
                bibleStory[categoryKey].toLowerCase().includes(searchQuery.toLowerCase());
        });

        setFilteredData(filtered);
    }, [searchQuery, bibleStoryData, i18n.language]);

    const getCategoryName = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.name_fr : bibleStory.name_en;
    };
    const getTitle = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.title_fr : bibleStory.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="pt-3 px-3 bottom-padding">
            {loading ? (
                <div className="w-100 all-bible-stories-holder pt-1">
                    <div style={{height: "20px", width: "130px"}}
                         className="rounded-2 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "65px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                </div>
            ) : (
                <div className="all-bible-stories-holder">
                    <div className="title-holder black-text-color fw-bold-600 mb-2 ps-1">
                        {t('bibleStories')}
                    </div>
                    {filteredData.length > 0 && (
                        filteredData.map((bibleStory) => (
                            <Link to={`/bible-stories/bible-story/${bibleStory.id}`} key={bibleStory.id}
                                  className="d-flex light-shadow w-100 rounded-3 mb-3">
                                <div className="img-holder rounded-3">
                                    {bibleStory.image ? (
                                        <img
                                            src={`${baseURL}thedss/images/bible-stories/${bibleStory.image}`}
                                            className="object-fit-cover border rounded-3 rounded-end-0"
                                            width={130}
                                            height={65}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={defaultImage}
                                            className="object-fit-cover border rounded-3 rounded-end-0"
                                            width={130}
                                            height={65}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <div className="w-100">
                                    <div className="p-2">
                                        <div className="name-holder small fw-bold-600 black-text-color pe-1"
                                             style={{
                                                 overflow: 'hidden',
                                                 display: '-webkit-box',
                                                 WebkitLineClamp: 2,
                                                 WebkitBoxOrient: 'vertical',
                                                 textOverflow: 'ellipsis'
                                             }}>
                                            {getTitle(bibleStory)}
                                        </div>
                                    </div>
                                    <div
                                        className="main-text-dark-color text-real-small fw-bold-500 text-end pb-1 pe-3">
                                        {bibleStory.episodes_num} Episode{bibleStory.episodes_num > 1 && 's'}
                                    </div>
                                </div>
                            </Link>
                        )))}
                </div>
            )}
        </div>
    );
};

export default PublicBibleStoriesFiltered;