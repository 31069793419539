import "./public.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicMessageService} from "../../_services";
import {TbMoodPuzzled} from "react-icons/tb";
import {Footer, Header, RandomVerses, ChallengesSection} from "./Public";
import {Link} from "react-router-dom";


const Home = () => {
    const {t, i18n} = useTranslation();
    const [categories, setCategories] = useState([]);
    const [selectedMood, setSelectedMood] = useState(0);
    const [loading, setLoading] = useState(true);
    const [section, setSection] = useState('challenge');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const data = await publicMessageService.getMessageCategories();
                setCategories(data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const getCategoryName = (category) => {
        return i18n.language.startsWith('fr') ? category.name_fr : category.name_en;
    };

    const handleOptionClick = (mood) => {
        setSelectedMood(mood);
    };

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-home-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <section className="main-choices-section px-3 py-3 my-1">
                    <div className="choices-content d-flex align-items-center p-1 rounded-3 small">
                        <div
                            className={`w-50 text-center py-1 rounded ${section === 'mood' ? 'bg-white' : 'text-white'}`}
                            onClick={() => setSection('mood')}>{t('verseForYou')}</div>
                        <div
                            className={`w-50 text-center py-1 rounded ${section === 'challenge' ? 'bg-white' : 'text-white'}`}
                            onClick={() => setSection('challenge')}>{t('betterMeChallenge')}</div>
                    </div>
                </section>
                <div className="horizontal-line mx-auto"/>
                {section === 'mood' && (
                    <div className="select-mood-section-holder pb-5">
                        <section className="select-mood-section px-3 pt-3 my-1">
                            <div className="custom-title-holder mb-4">
                                <div className="d-flex align-items-center">
                                    <div className="icon-holder rounded-circle p-2 me-2">
                                        <TbMoodPuzzled size="30" className="title-icon" color="#000"/>
                                    </div>
                                    <div className="title-text">
                                        <div className="fw-bold-600 small">
                                            {t('moodQuestion')}
                                        </div>
                                        <div className="small text-black-50">
                                            {t('selectMoodAndLet')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mood-options-holder d-flex align-items-center flex-wrap">
                                {loading ? (
                                    <>
                                        <div style={{height: "37px", width: "100px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "104px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "110px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "80px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "89px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "100px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "78px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "120px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "83px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                        <div style={{height: "37px", width: "102px"}}
                                             className="rounded-3 mb-3 me-2 main-loading-effect"/>
                                    </>
                                ) : (
                                    categories.map((category) => (
                                        <Link to={`/verse/${category.id}`} key={category.id}
                                              style={{backgroundColor: `${category.color}`}}
                                              className="py-2 px-4 rounded-3 mb-3 small c-pointer me-2 text-black"
                                              onClick={() => handleOptionClick(category.id)}>
                                            {getCategoryName(category)}
                                        </Link>
                                    ))

                                )}
                            </div>
                        </section>
                        <div className="horizontal-line mx-auto"/>
                        <RandomVerses/>
                    </div>
                )}
                {section === 'challenge' && (
                    <ChallengesSection/>
                )}
                <Footer/>
            </div>
        </div>
    );
};

export default Home;