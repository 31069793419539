import {Footer, Header, BibleStoryDetails, PublicBibleStoryEpisodes} from "./Public";
import {useParams} from "react-router-dom";

const BibleStory = () => {
    let {bid} = useParams();

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-bible-story-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <div className="bible-story-content bottom-padding">
                    <BibleStoryDetails bibleStoryId={bid}/>
                    <div className="horizontal-line mx-auto"/>
                    <PublicBibleStoryEpisodes bibleStoryId={bid}/>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default BibleStory;